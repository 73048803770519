import jquery from "jquery"
window.$ = window.jQuery = jquery

$(function(){

  $('.nav-small-burger').click(() => {
    $('.nav-small').toggleClass('d-none')
    $('.nav-small').toggleClass('d-flex d-lg-none')
  })

  $('.about-menu-btn').click(() => {
    let block = $('.nav-small-about-menu')
    block.toggleClass('d-none')
    block.toggleClass('d-flex')

    let arrow = $('.about-menu-btn').children('i')

    if (arrow) {
      arrow.toggleClass('fa-chevron-right')
      arrow.toggleClass('fa-chevron-down')
    }
  })

  $('.products-menu-btn').click(() => {
    let block = $('.nav-small-products-menu')
    block.toggleClass('d-none')
    block.toggleClass('d-flex')

    let arrow = $('.products-menu-btn').children('i')

    if (arrow) {
      arrow.toggleClass('fa-chevron-right')
      arrow.toggleClass('fa-chevron-down')
    }
  })

  $('.solutions-menu-btn').click(() => {
    let block = $('.nav-small-solutions-menu')
    block.toggleClass('d-none')
    block.toggleClass('d-flex')

    let arrow = $('.solutions-menu-btn').children('i')

    if (arrow) {
      arrow.toggleClass('fa-chevron-right')
      arrow.toggleClass('fa-chevron-down')
    }
  })

  $('.services-menu-btn').click(() => {
    let block = $('.nav-small-services-menu')
    block.toggleClass('d-none')
    block.toggleClass('d-flex')

    let arrow = $('.services-menu-btn').children('i')

    if (arrow) {
      arrow.toggleClass('fa-chevron-right')
      arrow.toggleClass('fa-chevron-down')
    }
  })
})

